.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* margin: 10rem 20rem 10rem 20rem; */

}


.contact .row h3 {
    margin-bottom: 1rem;
    text-transform: capitalize;
    color: var(--black);
    font-size: 2.5rem;
}

.contact .row .box {
    width: 100%;
    border-radius: .5rem;
    background-color: var(--light-bg);
    margin: 1rem 0;
    padding: 1.4rem;
    font-size: 1.8rem;
    color: var(--black);
}

.contact .row  {
    /* height: rem; */
    resize: none;
   
}

.contact .box-container {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: 2rem;
    /* height: 10vh; */
    
}

.contact .box-container .box {
    text-align: center;
    background-color: var(--main-color);
    border-radius: .5rem;
}

.contact .box-container .box i {
    font-size: 1.5rem;
    color: var(--white);
    margin: 1rem;
}

.contact .box-container .box h3 {
    font-size: 1.2rem;
    font-weight: bolder;
    color: var(--blue);
    margin:.5rem 0;
    padding-left: 3rem;
    padding-right: 3rem;
}

.contact .box-container .box p {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    font-weight: bolder;
    color: var(--white);
    margin-bottom: .5rem; 
}

.contact .box-container .box a {
    display: block;
    font-size: 1rem;
    font-weight: bolder;
    color: var(--white);
    margin-bottom: .5rem;
}
.contact .box-container .box a:hover {
    text-decoration: underline;
    color: var(--blue);
}

@media screen and (max-width: 850px) {
    .contact {
        display: block;
    }
        .contact .box-container .box  {
            max-width: 100%;
        }
        .contact .box-container .box h3 {
            font-size: 1rem;
            white-space: nowrap;
        }
    .form-container {
        margin: 6rem 2rem;
    }
    .form-container form {
        padding-top: 2rem;
        width: 90%;
    }

}
