/* BOARD CHAIRMAN */
.boardchair-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 0.2rem;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    /* padding: 1rem 1rem */
}

.board {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.board h3 {
    font-size: 2rem;
    text-align: center;
}

.board p {
    text-align: center;
}

.board .board-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.board-chair-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem
}

.boardchair-image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 7px;
    object-fit: cover;
    width: 100%;
}

.boardchair-image img {
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    margin-bottom: 7px;
}

.board-chair-card h3 {
    width: 100%;
    font-size: 20px;
    text-align: center;
    background: var(--main-color);
    color: var(--white);
    border-radius: 7px 7px 0 0;
    padding: 0 1rem
}

.board-chair-card h4 {
    width: 100%;
    font-size: 18px;
    text-align: center;
    background: var(--main-color);
    border-radius: 0 0 7px 7px;
    color: var(--blue-black);
    padding: 1rem 1rem
}

/* BOARD MEMBERS */
.board-members {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 300px 300px 300px;
    column-gap: 0.2rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.board-members-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 0.2rem;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    /* padding: 1rem 1rem */
}

.board-image {
    width: 100%;
    overflow: hidden;
    border-radius: 7px;
    /* padding: 1rem 1rem; */
    object-fit: cover;
}

.board-image img {
    /* width: 250px; */
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
    object-fit: cover;
}

.board-image:hover img {
    transform: scale(1.1);
}

.board-members-card h3 {
    width: 100%;
    font-size: 20px;
    text-align: center;
    background: var(--main-color);
    color: var(--white);
    border-radius: 7px 7px 0 0;
    padding: 0 1rem
}

.board-members-card h4 {
    width: 100%;
    font-size: 18px;
    text-align: center;
    background: var(--main-color);
    border-radius: 0 0 7px 7px;
    color: var(--blue-black);
    padding: 1rem 1rem
}
.view-profile{
    margin: 10px;
}
@media screen and (max-width: 850px) {
    .board {
        /* margin: 4rem 2rem; */
        margin: 0 auto;
    }

    .board-members {
        grid-template-columns: 1fr;
    }

    .boardchair-wrapper {
        width: 100%;
    }

    .board-chair-card {
        display: block;
        /* width: 60%; */
        width: 100%;
        margin: auto;
    }

    .board-members-card {
        width: 80%;
        margin: 10px auto;
    }

    .board-chair-card h3 {
        width: 100%;
    }

    .board-chair-card>h4 {
        width: 100%;
    }
}