.company-container {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 2rem;
    border-radius: 10px;
}

.flip-card {
    background-color: transparent; 
    width: 500px; 
    height: 500px; 
    border: 1px solid #f1f1f1;
    perspective: 1000px;
    border-radius: 20px;
   

}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 20px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
    cursor: pointer;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    border-radius: 20px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bbb;
    color: black;
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: relative;
    opacity: 0.3;
    text-align: center;
}
.text-container {
    position: absolute;
    top: 10px;
}

.text-container h3{
    color: #9b0000;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    padding: 2rem;
}
/* Style the back side */
.flip-card-back {
    background-color: white;
    box-shadow: inset 0 5px 25px 2px rgba(0, 0, 0, 0.2);
    transform: rotateY(180deg);
    border-radius: 20px;
}

.flip-card-back h4{
    font-size: 30px;
    padding: 10px;
}
.flip-card-back p{
    font-size: 18px;
    padding: 0 2rem;
    text-align: justify;
}


@media only screen and (max-width: 960px) {
    .company-container {
        grid-template-columns: 1fr;
    }
.text-container h3 {
    color: #9b0000;
    font-size: 1.5rem;
    font-weight: 700;
   
}
}

