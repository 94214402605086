header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
}

.navbar-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 150px;
    margin: 0 auto;
    padding: 10px 20px;
    background: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    margin: 0 auto;
    padding: 10px 20px;
    background: var(--main-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.nav-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 90px;
    margin: 0 auto;
    padding: 10px 20px;
    background: var(--white);
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 1;
}

.nav-image {
    position: relative;
    padding: 1.5rem 1.5rem;
    margin-right: auto;
    /* flex: 0; */
    margin-left: 0.5rem;
    /* margin-right:2rem; */
}

.nav-image img {
    width: 250px;
}

.logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
}

@media screen and (max-width: 850px) {
.nav-image img{
        width: 70%;
    }
}