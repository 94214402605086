.welcome-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    width: 100%;
    min-width: 1200px;
    background-color: var(--light-bg);
    /* padding: 20px; */
    margin: 10rem 0 0 0;
}

.welcome-content-left {
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
    /* background-color: #fff; */
}

.welcome-content-left img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border: 2px double var(--main-color);
    border-radius: 10px;
}

.welcome-content-right {
    /* background: #fff; */
    padding: 2rem;
    /* box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    height: 100%;
}

.welcome-content-right h1 {
    margin-top: 0;
    font-size: 30px;
    text-align: justify;
    margin-bottom: 20px;
    color: #028b00;
}

.welcome-content-right h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 600;
}

.welcome-content-right h3 {
    color: #838383;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: justify;
    margin-left: 2px;
}

.welcome-content-right p {
    color: #000;
    font-size: 20px;
    margin-bottom: 0;
    text-align: justify;
    line-height: 1.5;
}


@media only screen and (max-width: 960px) {
    .welcome-content {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .welcome-content-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        /* height: 100%; */
        /* background-color: var(--white); */
        margin: 0 auto;
    }
    .welcome-content-left img{
        width: 100%;
       
    }

    .welcome-content-right {
        width: 50%;
        background-color: var(--white);
        
    }

    .welcome-content-right h2,
    .welcome-content-right h3 {
        text-align: center;
    }
}