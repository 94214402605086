.tabs {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 1000px;
    height: auto;
    min-height: 90vh;
    background: var(--white);
    color: var(--black);
    margin: 1.5rem auto 1.5rem;
    padding: 2rem 2rem;
    border-radius: 2rem;
}

ul.nav {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--main-color);
    /* border-radius: 2rem; */
    padding-left: 0px;
    background: var(--main-color);
    color: var(--white)
}

ul.nav li {
    width: 100%;
    padding: 1rem;
    list-style: none;
    text-align: left;
    cursor: pointer;
    transition: all 0.7s;
    font-size: 20px;
    font-weight: bolder;
    /* border-radius: 2rem 2rem 0 0; */
}

ul.nav li:first-child() {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
}

ul.nav li:last-child() {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
}


ul.nav li:hover {
    background: var(--smoke);
    color: var(--main-color);
}

ul.nav li.active {
    background: var(--main-color);
    color: var(--smoke);
}

ul.nav li.active:hover {
    background: var(--smoke);
    color: var(--main-color);
}

.history,
.mission,
.core-functions,
.board-composition,
.shareholders .our-subsidiaries,
.operating-structure,
.track-record {
    display: flex;
    flex-direction: column;
}

.history h1,
.mission h1,
.core-functions h1,
.board-composition h1,
.shareholders h1,
.our-subsidiaries h1,
.operating-structure h1,
.track-record h1 {
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    text-transform: capitalize;
    color: var(--main-color);
    margin-bottom: 1rem;
}

.history h3,
.mission h3,
.core-functions h3,
.board-composition h3,
.shareholders h3,
.our-subsidiaries h3,
.operating-structure h3,
.track-record h3 {
    font-size: 18px;
    font-weight: bolder;
    text-align: left;
    text-transform: capitalize;
    color: var(--main-color);
    margin-bottom: 1rem;
}

.history p,
.mission p,
.core-functions p,
.board-composition p,
.shareholders p,
.our-subsidiaries p,
.operating-structure p,
.track-recors p {
    font-size: 18px;
    text-align: justify;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

ol {
    list-style-type: lower-alpha;
    padding: 0;
}

ol {
    list-style-type: lower-alpha;
    padding: 0;
}

ul {
    list-style-type: square;
    text-align: left;
    list-style-position: inside;
    color: #000;
    line-height: 1.6;
    padding-left: 2rem;
}

ol li {
    color: #000;
    font-family: fantasy;
    font-size: 1em;
    content: ">>  ";
}

.track-record {
    display: flex;
    gap: 2rem;
    justify-content: left;
    align-items: left;
    flex-flow: row wrap;
    align-content: space-evenly;
    background: whitesmoke;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 1rem;
    line-height: 1.2;
}

.track-record-box {
    border: 5px double green;
    border-radius: 2rem;
    padding: 1em;
    gap: 5rem;
    margin: 1em auto 1rem;
    max-width: 500px;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.track-record img {
    display: flex;
    gap: 2rem;
    border: 5px double #014b22;
    border-radius: 2px;
    float: center;
    padding: 0px 0px;
    max-width: 200px;
    max-height: 200px;
}

.sharehoders .sharehoders-card {
    display: flex;
    margin-left: 2rem;
}

.holders-card {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 5rem;
}

.col-md-2 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    align-items: center;
    justify-content: center;
}

.logo-image {
    width: 150px;
    margin: 10px auto;
}
.logo-image img{
    width: 100%;
    height: 50%;
}
@media screen and (max-width: 850px) {
    .tabs {
        grid-template-columns: 1fr;
        margin: 2rem auto;
        width: 100%;
    }

    ul.nav {
        width: 100%;
    }

    ul.nav li {
        white-space: nowrap;
        text-align: center;
    }

    .history,
    .mission,
    .core-functions,
    .board-composition,
    .shareholders .our-subsidiaries,
    .operating-structure,
    .track-record {
        margin-left: 2rem;
    }

    .holders-card {
        display: flex;
        flex-direction: column;
    }

    .logo-image {
        display: none;
    }
}


.sub-board {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 200px 200px 200px;
    gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.sub-board-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    cursor: pointer;
    padding: 1rem 0.8rem
}

.sub-board-image {
    width: 100%;
    overflow: hidden;
    border-radius: 7px;
}

.sub-board-image img {
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.sub-board-image:hover img {
    transform: scale(1.1);
}

.sub-board-card h4 {
    width: 100%;
    font-size: 14px;
    text-align: center;
    background: var(--main-color);
    color: var(--white);
    border-radius: 7px 7px 0 0;
}

.sub-board-card h5 {
    width: 100%;
    font-size: 12px;
    text-align: center;
    background: var(--main-color);
    border-radius: 0 0 7px 7px;
    color: var(--blue-black);
}

@media screen and (max-width: 850px) {
    .sub-board {
        grid-template-columns: 1fr;
    }

    .sub-board-card {
        width: 60%;
        margin: auto;
    }
}