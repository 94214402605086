.modal {
    position: fixed;
    inset: 0;
    /* inset sets all 4 values (top, left, right, bottom) much like how we set margin and padding etc*/
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    /* padding: 40px 20px 20px; */
    transform: scale(1);
    z-index: 1;
}

.modal-content {
    position: fixed;
    top: 255px;
    left: 20%;
    right: 20%;
    background-color: var(--white);
    color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    text-align: justify;
    padding: 2rem;
    border-radius: 10px;
    /* z-index: 999; */
}

.modal-enter-done {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}

.modal-exit {
    opacity: 0;
    transform: scale(0.4);
}

.profile-card {
    width: 30%;
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    background: var(--main-color)
}

.profile-card img {
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.profile-text {
    text-align: justify;
    line-height: 1.5;
    font-size: 16px;
}

@media screen and (max-width: 850px) {
    .modal {
        width: 100%;
    }
    .modal-content {
        width: 100%;
    }
.profile-card img {
    width: 100%;
}
.boardchair-wrapper {
    width: 100%;
}
}