.marquee {
    display: flex;
    justify-content: space-evenly;
    gap: 5rem;
    font-size: 16px;
}

.nthc-ltd {
    display: flex;
}

.nthc-ltd h1 {
    color: var(--white);
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
}

.nthc-ltd h2 {
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    animation: blinker 0.5s linear infinite;
    color: var(--black);
}

.marquee-text {
    display: flex;
}

.marquee-text h1 {
    color: var(--white);
    font-size: 20px;
    font-weight: 900;
    padding: 10px;
}

.marquee-text h2 {
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    animation: blinker 1s linear infinite;
    color: var(--black);
}
.marquee-text a {
    color: var(--white);
    font-size: 20px;
    font-weight: 900;
    padding: 5px;
    width: 50%;
}


@keyframes blinker {
    50% {
        opacity: 0.7;
    }
}