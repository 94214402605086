/* Slideshow */
.slideshow {
    margin: 0;
    top: 110px;
    right: 0;
    width: 100vw;
    height:30%;
    overflow: hidden;
    position: relative;
    background-color: var(--white);
   
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    /* height: 400px; */
    width: 100vw;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    background-color: #fff;
}
.slide img{
    width: 100%;
    /* height: auto; */
    object-fit: cover;
    /* opacity: 1; */
}
/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: var(--white);
}

.slideshowDot.active {
    background-color: #6a0dad;
}

.leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 32px;
    font-size: 45px;
    color: #000;
    z-index: 1;
    cursor: pointer;
}

.rightArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 32px;
    font-size: 45px;
    color: #000;
    z-index: 1;
    cursor: pointer;

}

.leftArrowStyles:hover,
.rightArrowStyles:hover{
    color: #6a0dad;
    cursor: pointer;
}

.description {
    width: 600px;
    position: absolute;
    z-index: 999;
    top: 20%;
    left: 20%;
}

.description h1 {
    font-size: 3rem;
    font-weight: bolder;
    background-clip: text;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: var(--black); */
}

.description p {
    font-size: 1.2rem;
    color: #444;
    /* -webkit-background-clip: text; */
}
.description a {
    display: inline-block;
    text-decoration: none;
    background: #25A560;
    font-size: 15px;
    padding: 10px 35px;
    border-radius: 30px;
    margin-top: 25px;
    margin-left: 50px;
    /* -webkit-background-clip: text; */
}

@media screen and (max-width: 1024px) {
    .leftArrowStyles,
        .rightArrowStyles {
            
            opacity: 0;
        }
    
        .leftArrowStyles:hover,
        .rightArrowStyles:hover {
            color: var(--black);
            opacity: 0.1;
            z-index: 1
        }
}
@media screen and ( max-width: 850px ) {
    .slideshow>.slide>img{
        display: block;
        padding: 0;
        margin: 0;
        border: 0;
        width: 100%;
        object-fit: cover;
        height: auto;
    }
    .leftArrowStyles,
    .rightArrowStyles {
        z-index: 0
    }
    .leftArrowStyles:hover,
    .rightArrowStyles:hover {
        color: var(--black) ;
        opacity: 1;
        z-index: 1
    }
}