.photo-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0px auto;
    margin-top: 30px;
    max-width: 600px;
    overflow: hidden;
    border: 10px solid white;
     border-radius: 7px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.4); 
}
.close-icon {
    display: flex;
    position:absolute;
    right: 0;
    align-items:flex-end;
    justify-content:flex-end;
}
.photo-header {
   font-size: 1.5rem;
    color: var(--main-color);
    border-bottom: var(--border);
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
}
.close-icon .fa-times {
    color: var(--black);
    font-size: 1.2rem;
    font-weight: 900;
}

.photo-slide {
    position: relative;
    background-color: rgb(155, 167, 179);
    width: 100%;
    animation-name: fade;
    animation-duration: 2s;
}

.photo-slide img {
    width: 100%;
    height:500px;
}
.right-arrow {
    position: absolute;
    top: 40%;
    right: 0;
    font-size: 2rem;
    color:var(--black);
    z-index: 10;
    cursor: pointer;
    user-select: none;
}
.left-arrow {
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 2rem;
    color: var(--black);
    z-index: 10;
    cursor: pointer;
    user-select: none;
}
.left-arrow:hover,
.right-arrow:hover{
     color:var(--main-color);
     opacity: 0.5;
}



@keyframes fade {
    from{opacity: 0.2;}
    to{opacity: 0.8;}
}
    
@media screen and (max-width: 850px) {
    .photo-slider {
        width: 100%;
    }
}
    
