.group {
    /* margin: 4rem 6rem; */
    color: #2a2a2a;
}

.group h3 {
    font-size: 2rem;
    text-align: center;
}

.group p {
    text-align: center;
}

.group-chair-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    /* background: transparent; */
}

.group-chair-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 7px;
}

.group-chair-image img {
    width: 25%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.group-chair-card h3 {
    margin-top: 5px;
    width: 25%;
    font-size: 20px;
    text-align: center;
    background: var(--main-color);
    color: var(--white);
    border-radius: 7px 7px 0 0;
}

.group-chair-card h4 {
    width: 25%;
    font-size: 18px;
    text-align: center;
    background: var(--main-color);
    color: var(--blue-black);
    border-radius: 0 0 7px 7px;
}

.group-members {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 300px 300px 300px;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.group-members-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem
}

.group-image {
    width: 100%;
    overflow: hidden;
    border-radius: 7px;
}

.group-image img {
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.group-image:hover img {
    transform: scale(1.1);
}

.group-members-card h3 {
    width: 100%;
    font-size: 20px;
    text-align: center;
    background: var(--main-color);
    color: var(--white);
    border-radius: 7px 7px 0 0;

}

.group-members-card h4 {
    width: 100%;
    font-size: 18px;
    text-align: center;
    background: var(--main-color);
    border-radius: 0 0 7px 7px;
    color: var(--blue-black);
}

@media screen and (max-width: 850px) {
    .group {
        margin: 4rem 2rem;
    }

    .group-members {
        grid-template-columns: 1fr;
    }

    .group-chair-card {
        display: block;
        width: 80%;
        margin: auto;
    }

    .group-chair-image img {
        width: 100%;
    }

    .group-members-card {
        width: 60%;
        margin: auto;
    }
}