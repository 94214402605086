.message-box {
    position: fixed;
    inset: 0; /* inset sets all 4 values (top, left, right, bottom) much like how we set margin and padding etc*/
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    padding: 40px 20px 20px;
    transform: scale(1); 
    
}

.message-box-content {
    position: fixed;
    top: 280px;
    left: 30%;
    right: 30%;
    background-color: var(--main-color);
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    text-align: justify;
    padding: 2rem;
    border-radius: 10px;
    z-index: 999;
}

.message-box-content button {
    bottom: 0;
    background-color: var(--white);
    color: var(--blue-black);
    width: 200px;
    font-size: 16px;
    font-weight: bolder;
}

.modal-enter-done {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}
.modal-exit {
    opacity: 0;
    transform: scale(0.4);
}
